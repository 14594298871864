<style lang="less" scoped>
  .org-page {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  .toolbox {
    padding: 10px;
    background-color: #FFF;
    border-radius: 5px;
  }
  .query-form {
    margin-top: 10px;
  }
  .table {
    margin-top: 10px;
    height: 0;
    flex: 1;
  }
  .title {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
</style>

<template>
  <div class="org-page">
    <div class="toolbox">
      <div class="title">
        <fm-title title-text="绩效统计"></fm-title>
        <div>
          <Button :loading="loading.load" style="margin-right: 10px;" icon="md-refresh-circle" @click="loadData">刷新</Button>
          <Button style="margin-right: 10px;" v-if="$authFunsProxy['output']" icon="ios-apps" @click="outPut">导出</Button>
        </div>
      </div>
      <fm-form class="query-form" inline>
        <fm-form-item style="margin-bottom: 0;" label="姓名">
          <fm-input-new @enten="loadData" @blur="loadData" clearable v-model="query.pfmWorkerName" />
        </fm-form-item>
        <fm-form-item style="margin-bottom: 0;" label="考核单元">
          <Select @on-change="loadData" filterable clearable v-model="query.pfmUnitId">
            <Option v-for="item in unitList" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item style="margin-bottom: 0;" label="最小月份">
          <DatePicker @on-change="loadData" v-model="query.minMonth" type="month"/>
        </fm-form-item>
        <fm-form-item style="margin-bottom: 0;" label="最大月份">
          <DatePicker @on-change="loadData" v-model="query.maxMonth" type="month"/>
        </fm-form-item>
      </fm-form>
    </div>
    <div class="table" ref="table">
      <Table :loading="loading.load" v-if="tableHeight !== null" :height="tableHeight" :columns="columns" :data="dataList">
      </Table>
    </div>
  </div>
</template>

<script>
import { request } from '@/api'

import {
  fileHepler
} from '@/fmlib'

function getDefaultQuery () {
  return {
    pfmWorkerName: null,
    pfmUnitId: null,
    minMonth: new Date(),
    maxMonth: new Date()
  }
}

export default {
  data () {
    return {
      dataList: [],
      query: getDefaultQuery(),
      loading: {
        load: false
      },
      tableHeight: null
    }
  },
  computed: {
    unitList () {
      return this.$store.getters.pfmUnitList
    },
    columns () {
      const columns = [
        { title: '序号', key: 'index' },
        { title: '月份', key: 'month' },
        { title: '姓名', key: 'pfmWorkerName' },
        { title: '核算单元', key: 'pfmUnitName' },
        { title: '普通工作量绩效', key: 'pfmGzl' },
        { title: '重点项目绩效', key: 'singleValue' },
        { title: '合计', key: 'total' },
      ]
      return columns
    }
  },
  methods: {
    outPut () {
      fileHepler.outPutTableData(this.dataList, this.columns.map(v => {
        return {
          title: v.title,
          field: v.key
        }
      }), '发放结果.xls')
    },
    async loadData () {
      let p = {
        pfmWorkerName: this.query.pfmWorkerName ? this.query.pfmWorkerName : null,
        pfmUnitId: this.query.pfmUnitId ? this.query.pfmUnitId : null,
        minMonth: this.query.minMonth ? this.$datetime.format(this.query.minMonth, 'Y-M') : null,
        maxMonth: this.query.maxMonth ? this.$datetime.format(this.query.maxMonth, 'Y-M') : null
      }
      let url = null
      if (this.$authFunsProxy['get']) {
        url = '/performance/pfm_worker_month/result'
      } else if (this.$authFunsProxy['getByAuth']) {
        url = '/performance/pfm_worker_month/result/by_auth'
      }
      if (url) {
        this.loading.load = true
        let res = await request(url, 'get', p)
        this.dataList = res.map((v, i) => {
          return {
            index: i + 1,
            month: v.month,
            pfmWorkerName: v.pfmWorkerName,
            pfmUnitName: v.pfmUnitName,
            pfmGzl: v.pfmUnitMonthType === 'other' ? v.avgValue : v.unitValue,
            singleValue: v.singleValue,
            total: v.total
          }
        })
        this.loading.load = false
      }
    },
    resize () {
      this.tableHeight = this.$refs.table.offsetHeight
    }
  },
  mounted() {
    this.loadData()
    this.$store.dispatch('loadPfmUnitList')
    window.addEventListener('resize', this.resize)
    this.resize()
  },
  destroyed() {
    window.removeEventListener('resize', this.resize)
  },
}
</script>